import L from 'leaflet';

import { Map, PreLoader } from '@/components';

import { cityCenterCoords } from '@/projects/on_demand/constants';
import type { GeoJsonObject } from 'geojson';
import { GeoJSON, Marker, useMap, useMapEvents } from 'react-leaflet';
import locationSrc from './assets/location.png';

import DriverPositionUpdater from '@/on_demand/components/DriverPositionUpdater';
import PhoneModal from '@/on_demand/components/PhoneModal';
import { useAppDispatch, useAppSelector } from '@/projects/on_demand/app/hooks';
import { BusMarker } from '@/projects/on_demand/components/BusMarker';
import { DragMarker } from '@/projects/on_demand/components/DragMarker';
import { Info } from '@/projects/on_demand/components/Info';
import { Modal } from '@/projects/on_demand/components/Modal';
import {
  getTourInfoAsync,
  payAsync,
  setDropOffIndex,
  setDropOffRBS,
  setPickupIndex,
  setPickupRBS,
  tourSelector,
} from '@/projects/on_demand/store/slices/tourSlice';
import { getNearestRBS } from '@/projects/on_demand/utils';
import { setPhone } from '@/store/slices/mainSlice';
import { axiosInstance } from '@/utils/AxiosInstance';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './Pin.module.scss';
import './styles.scss';

const mainOptions = { color: '#5a96ff', weight: 6 };

const locationIcon = new L.Icon({
  iconUrl: locationSrc,
  iconSize: [56, 56],
  iconAnchor: [22, 30],
});

const pointIcon = L.divIcon({
  className: styles.point,
  html: `<div class="${styles.point}"><div class="${styles.pointInner}"></div></div>`,
  iconSize: [12, 12],
  iconAnchor: [6, 6],
});

const getUrl = (points: string): string => {
  return (
    `https://osrm.uvu.kz/route/v1/foot/${points}?alternatives=false&steps=false` +
    '&geometries=geojson&overview=full&annotations=false'
  );
};

interface ORSMRoute {
  geometry: GeoJsonObject;
}

interface OSRMResponse {
  code: 'Ok' | 'InvalidUrl' | 'InvalidService' | 'InvalidQuery';
  routes: ORSMRoute[];
}

function MapEventComponent() {
  const dispatch = useAppDispatch();
  const { tour, dropoffIndex } = useAppSelector(tourSelector);
  const map = useMapEvents({
    locationfound: (location) => {
      if (tour && tour.bus_stops && tour.bus_stops.length > 1) {
        let nearestRBS = getNearestRBS(location.latlng, tour.bus_stops);
        let index = tour.bus_stops.indexOf(nearestRBS);
        if (dropoffIndex !== null && index >= dropoffIndex) {
          index = dropoffIndex - 1;
          if (index < 0) index = 0;
          nearestRBS = tour.bus_stops[index];
        }
        dispatch(setPickupIndex(index));
        dispatch(setPickupRBS(nearestRBS));
      }
      L.marker(location.latlng, {
        icon: locationIcon,
      }).addTo(map);
    },
  });

  useEffect(() => {
    map.locate();
  }, [map]);

  return null;
}

const MapBoundsUpdater = ({ bounds }: { bounds: L.LatLngBoundsExpression | null }) => {
  const map = useMap();

  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds, { padding: [100, 100] });
    }
  }, [bounds, map]);

  return null;
};

export const TourPage = () => {
  const dispatch = useAppDispatch();
  const { tour, driverPosition, pickupRBS, dropOffRBS, pickupIndex, dropoffIndex, isLoading, isSendingData } =
    useAppSelector(tourSelector);
  const [searchParams] = useSearchParams();
  const route_id = searchParams.get('route_id');
  const [bounds, setBounds] = useState<L.LatLngBoundsExpression | null>(null);
  const [geoData, setGeoData] = useState<GeoJsonObject | null>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState<boolean>(false);
  const pickupMarkerRef = useRef<L.Marker>(null);
  const dropoffMarkerRef = useRef<L.Marker>(null);

  useEffect(() => {
    if (tour && tour.bus_stops) {
      const points = tour.bus_stops.map((bs) => L.latLng(bs.location.lat, bs.location.lng));
      if (points.length > 0) {
        setBounds(L.latLngBounds(points));
      }
    }
  }, [tour]);

  useEffect(() => {
    if (route_id) {
      dispatch(
        getTourInfoAsync({
          id: Number(route_id),
        })
      );
    }
  }, [dispatch, route_id]);

  useEffect(() => {
    const fetchData = async () => {
      if (tour) {
        const locations = tour.bus_stops.map((bs) => [bs.location.lng, bs.location.lat]) as [number, number][];
        const pointsString = (locations.length > 0 ? locations : []).map((point) => point.join(',')).join(';');
        const url = getUrl(pointsString);
        const response = await axiosInstance.get<OSRMResponse>(url);
        if (response.code !== 'Ok' || !response.routes || response.routes.length === 0) {
          console.log('No route returned');
          return null;
        }
        setGeoData(response.routes[0].geometry);
      }
    };

    if (tour) {
      fetchData().catch(() => console.error('Some error happened.'));
    }

    return () => setGeoData(null);
  }, [tour]);

  useEffect(() => {
    if (tour && tour.bus_stops && tour.bus_stops.length > 1) {
      const pickup = tour.bus_stops[0]; // The first stop
      const dropoff = tour.bus_stops[tour.bus_stops.length - 1]; // The last stop

      // Update states
      dispatch(setPickupIndex(0));
      dispatch(setPickupRBS(pickup));
      dispatch(setDropOffIndex(tour.bus_stops.length - 1));
      dispatch(setDropOffRBS(dropoff));
    }
  }, [tour, dispatch]);

  useEffect(() => {
    const modalClosed = localStorage.getItem('modalClosed');
    if (!modalClosed) {
      setModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
    localStorage.setItem('modalClosed', 'true');
  };

  const handleSubmit = (phone: string) => {
    setPhone(phone);
    if (tour && pickupRBS && dropOffRBS) {
      dispatch(
        payAsync({
          tour_id: tour.tour_id,
          phone,
          domain: window.location.hostname,
          pickup_rbs_id: pickupRBS.id,
          dropoff_rbs_id: dropOffRBS.id,
        })
      );
    }
  };

  return (
    <>
      {isLoading && <PreLoader showBackground showText />}
      {isSendingData && <PreLoader />}
      {isModalOpen && <Modal onClose={handleCloseModal} />}
      {isPhoneModalOpen && <PhoneModal onSubmit={handleSubmit} onClose={() => setIsPhoneModalOpen(false)} />}
      <Map center={cityCenterCoords}>
        {tour && tour.bus_stops.map((bs) => <Marker position={bs.location} icon={pointIcon} key={bs.id} />)}

        {dropOffRBS && tour && (
          <DragMarker
            ref={dropoffMarkerRef}
            bs={dropOffRBS}
            busStops={tour.bus_stops}
            kind="finish"
            onUpdatePosition={(bs, index) => {
              if (pickupIndex !== null && index <= pickupIndex) {
                index = pickupIndex + 1;
                const length = tour.bus_stops.length - 1;
                if (index > length) index = length;
                bs = tour.bus_stops[index];
              }
              dispatch(setDropOffIndex(index));
              dispatch(setDropOffRBS(bs));
              if (dropoffMarkerRef.current) {
                dropoffMarkerRef.current.setLatLng(bs.location);
              }
            }}
          />
        )}

        {pickupRBS && tour && (
          <DragMarker
            ref={pickupMarkerRef}
            bs={pickupRBS}
            busStops={tour.bus_stops}
            kind="start"
            onUpdatePosition={(bs, index) => {
              if (dropoffIndex !== null && index >= dropoffIndex) {
                index = dropoffIndex - 1;
                if (index < 0) index = 0;
                bs = tour.bus_stops[index];
              }
              dispatch(setPickupIndex(index));
              dispatch(setPickupRBS(bs));
              if (pickupMarkerRef.current) {
                pickupMarkerRef.current.setLatLng(bs.location);
              }
            }}
          />
        )}
        {driverPosition && (
          <BusMarker position={[driverPosition.lat, driverPosition.lng]} rotation={driverPosition.course} />
        )}
        {bounds && <MapBoundsUpdater bounds={bounds} />}
        <MapEventComponent />
        {geoData && <GeoJSON data={geoData} style={() => mainOptions} />}
      </Map>
      <Info onBtnClick={() => setIsPhoneModalOpen(true)} />
      {tour && <DriverPositionUpdater tourId={tour.tour_id} />}
    </>
  );
};
