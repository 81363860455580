import { DateType } from '@/@types/shuttle/date';
import { Btn, PreLoader, TitleBlock } from '@/components';
import Bonus from '@/components/shuttle/molecules/Bonus';
import Driver from '@/components/shuttle/molecules/Driver';
import PhoneForm from '@/components/shuttle/molecules/PhoneForm';
import Subscription from '@/components/shuttle/molecules/Subscription';
import { FakePhoneNumber } from '@/constants';
import { useAppDispatch } from '@/shuttle/app/hooks';
import {
  buySubscriptionAsync,
  getTourInfoAsync,
  paymentSelector,
  setSubscriptionKind,
} from '@/shuttle/store/slices/paymentSlice';
import { setIsTitleHidden, shuttleSelector } from '@/shuttle/store/slices/shuttleSlice';
import { mainSelector } from '@/store/slices/mainSlice';
import { setToaster } from '@/store/slices/toasterSlice';
import { isValidNumber, parseNumber } from '@/utils/phone';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';

const PaymentController: FC = () => {
  const dispatch = useAppDispatch();
  const { isTitleHidden } = useSelector(shuttleSelector);
  const { isMobile, phone } = useSelector(mainSelector);
  const {
    isLoading,
    driver,
    subscriptionKind,
    bonusAmount,
    oneTripPrice,
    routeDirection,
    subscriptionPrice,
    subscriptionRidePrice,
    upcomingTourDates,
  } = useSelector(paymentSelector);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tour_id = searchParams.get('tour_id');
  const pickup_stop_id = searchParams.get('pickup_stop_id');
  const delivery_stop_id = searchParams.get('dropoff_stop_id');
  const is_title_hidden = searchParams.get('is_title_hidden');
  const error = searchParams.get('error');
  const phoneFromSearchParams = searchParams.get('phone');

  const [passengerCount, setPassengerCount] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<DateType | null>(null);

  const fullPhone = useMemo(() => {
    if (phoneFromSearchParams) {
      return phoneFromSearchParams.startsWith('+') ? phoneFromSearchParams : `+${phoneFromSearchParams}`;
    }

    const phoneFromLocalStorage = localStorage.getItem('phone');
    if (!phone && phoneFromLocalStorage) {
      return phoneFromLocalStorage;
    }

    return phone;
  }, [phone, phoneFromSearchParams]);

  useEffect(() => {
    if (error) {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'error',
          message: 'Произошла ошибка при оплате',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!tour_id) {
      dispatch(
        setToaster({
          isVisible: true,
          type: 'warning',
          message: 'Не указана поездка',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour_id]);

  useEffect(() => {
    if (is_title_hidden) {
      const value = is_title_hidden === 'true';
      dispatch(setIsTitleHidden(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_title_hidden]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (fullPhone && fullPhone !== FakePhoneNumber) {
      params.append('phone', encodeURIComponent(fullPhone));
    }

    if (tour_id) {
      dispatch(
        getTourInfoAsync({
          tourId: tour_id,
          paramsString: params.toString(),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour_id, fullPhone]);

  useEffect(() => {
    if (upcomingTourDates) {
      setSelectedDate(upcomingTourDates[0]);
    }
  }, [upcomingTourDates]);

  const handleSubmit = () => {
    if (!tour_id) return;
    // Adds start_date only if selectedDateValue is not null.
    const data = Object.assign(
      {
        tour_id: Number(tour_id),
        phone: parseNumber(fullPhone),
        passenger_count: passengerCount,
        kind: subscriptionKind,
        pickup_point_id: Number(pickup_stop_id) || localStorage.getItem('pickup_point_id'),
        delivery_point_id: Number(delivery_stop_id) || localStorage.getItem('delivery_point_id'),
        is_mobile: isMobile,
      },
      selectedDate === null
        ? null
        : {
            start_date: selectedDate.value.toISOString().split('T')[0],
            // eslint-disable-next-line no-mixed-spaces-and-tabs
          }
    );

    dispatch(buySubscriptionAsync(data));
  };

  return (
    <>
      {isLoading ? (
        <PreLoader showText />
      ) : (
        <div className={styles.form}>
          {!isTitleHidden ? (
            <TitleBlock navigate={navigate} isMobile={isMobile} withBackBtn={true} withCloseBtn={false}>
              Выбери подписку
            </TitleBlock>
          ) : null}
          {driver ? (
            <Driver
              route_name={driver.route_name}
              photo_url={driver.photo_url}
              name={driver.name}
              car_name={driver.car_name}
              car_number_plate={driver.car_number_plate}
            />
          ) : null}
          {!isValidNumber(phone) ? <PhoneForm /> : null}
          <Subscription
            subscriptionKindValue={subscriptionKind}
            passengerCount={passengerCount}
            oneTripPrice={oneTripPrice}
            subscriptionRidePrice={subscriptionRidePrice}
            subscriptionPrice={subscriptionPrice}
            onSubscriptionClick={(value) => dispatch(setSubscriptionKind(value))}
            onPassengerChange={(value) => setPassengerCount(value)}
            routeDirection={routeDirection}
          />
          {bonusAmount && bonusAmount > 0 && (
            <Bonus
              subscriptionKindValue={subscriptionKind}
              amount={Number(bonusAmount)}
              passengerCountValue={passengerCount}
              onlineOneTripPaymentAmountValue={oneTripPrice || 0}
              subscriptionPrice={subscriptionPrice || 0}
            />
          )}
          <div>
            <Btn
              color="black"
              disabled={!isValidNumber(phone) || oneTripPrice === 0 || subscriptionPrice === 0}
              onClick={handleSubmit}
              customClassName={styles.btn}
              isFixedBottom={true}
            >
              Забронировать
            </Btn>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentController;
