import { getDriverPositionInfoAsync } from '@/on_demand/store/slices/tourSlice';
import { useAppDispatch } from '@/projects/on_demand/app/hooks';
import { showToasterWithDelay } from '@/store/slices/toasterSlice';
import React, { useCallback, useEffect } from 'react';

const DriverPositionUpdater: React.FC<{ tourId: number }> = ({ tourId }) => {
  const dispatch = useAppDispatch();

  // Function to generate a random delay
  const getRandomDelay = () => {
    const start = 5000;
    const end = 10000;
    const step = 1000;

    // Possible delays in milliseconds [5000, 6000, 7000, 8000, 9000, 10000]
    const delays = Array.from({ length: Math.floor((end - start) / step) + 1 }, (_, i) => start + i * step);
    return delays[Math.floor(Math.random() * delays.length)];
  };

  // Main function to update the driver's position
  const updateDriverPosition = useCallback(async () => {
    try {
      // Send the request
      const result = await dispatch(getDriverPositionInfoAsync({ tour_id: tourId })).unwrap();

      // If we get the result, plan the next calling
      if (result) {
        const delay = getRandomDelay();
        setTimeout(updateDriverPosition, delay); // Recursive calling
      }
    } catch (error) {
      // If the error, then stop pulling.
      console.error('Error fetching driver position:', error);
      dispatch(
        showToasterWithDelay({
          isVisible: true,
          type: 'error',
          message: 'Отслеживание водителя не доступно. Начнется за час до начала маршрута',
        })
      );
    }
  }, [dispatch, tourId]);

  useEffect(() => {
    // Start the update process on component mount
    const timeoutId = setTimeout(updateDriverPosition, getRandomDelay());

    // Clear the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [dispatch, tourId, updateDriverPosition]);
  return null;
};

export default DriverPositionUpdater;
